import React, { useState, useEffect, useCallback, useRef } from 'react'
import Link from 'next/link'
import { useSelector, useDispatch, batch } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { ReactComponent as ClockIcon } from '../assets/userEvent/timeout.svg'
import { ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { useTheme } from '../context/theme'

import RequirementPill from '../components/DesignerCommon/RequirementPill'
import NestedFeed from '../components/DesignerFeed/NestedFeed'
import DesignFeedCard from '../components/DesignerDesigns/cards/DesignFeedCard'
import JoinedDesignCard from '../components/DesignerDesigns/cards/JoinedDesignCard'
import BackButton from '../components/navs/BackButton'
import ArrowIcon from '../assets/arrow-down.svg'

import { timeLeft } from '../utils/utils'
import { DMPrimaryButton, DMPrimaryAltButton, DMShareModal } from '@decormatters/dm-theme'
import { DMTabs } from '../components/DesignerTheme/DMTabs'
import { convertDataToArtboard, convertiOSDataToArtboard } from '../utils/fetchUtils'
import { fetchChallengeDraftId } from '../redux/designerReducers/challengeReducer'
import { fetchUserDesign } from '../redux/designerReducers/designsReducer'
import { fetchSaved, setSavedLikes } from '../redux/designerReducers/userSavedReducer'
import { fetchFeed, setAttr } from '../redux/feedInfiniteSlice'
import { FEED_RESET, STATIC_COIN_AMOUNT } from '../redux/constants'

import { getChallengeById, getMyChallengeStatus, getChallengeByType, getUserEventById } from '../redux/apis'
import Detail from '../components/DesignerCommon/Detail'

import NoItems from '../assets/noitems.png'
import { ASSET_URL } from '../constants/ApiEndpoints'
import { useRouter as useHistory } from 'next/router'
import { setLocationState } from '../redux/designerReducers/locationStateReducer'
import { getMembershipType } from '../utils/getMembershipType'
import { setShowMembershipModal } from '../redux/designerReducers/designerReducer'

export const EventDetailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;

  @media (max-width: 1100px) {
    gap: 0;
  }
`

export const BackButtonWrapper = styled.div`
  position: fixed;
  top: 90px;
  left: 32px;

  @media (max-width: 1100px) {
    display: none;
  }
`

export const Content = styled.div`
  width: 80vw;
  max-width: 1300px;
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  padding-top: 50px;
  align-items: stretch;

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    padding-top: 16px;
  }
`

export const LeftPart = styled.div`
  width: 560px;

  @media (max-width: 1100px) {
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const RightPart = styled.div`
  flex: 1;
  margin-left: 60px;
  box-sizing: border-box;
  background: #f9f9f9;
  padding: 29px 24px;
  border-radius: 16px;

  & p {
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
  }

  @media (max-width: 1100px) {
    background: transparent;
    margin-left: 0;
    margin-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 1100px) {
    margin-top: 0;
    padding-bottom: 0;
  }
`

export const DesignsGrid = styled.div`
  width: 100%;
  min-height: 300px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 14px;
  position: relative;

  @media (max-width: 1100px) {
    grid-template-columns: ${props => (props.designs ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)')};
    padding: 0 10px;
    gap: 8px;
    box-sizing: border-box;
    min-height: 110px;
  }
`

export const BigActionButton = styled.button`
  width: 495px;
  height: 45px;
  background: ${props => (props.disabled ? '#E5E5E5' : '#ff5e6d')};
  border-radius: 93px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${props => (props.disabled ? 'black' : '#ffffff')};
  flex-shrink: 0;
  border: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  z-index: 999;
  position: fixed;
  bottom: 60px;

  &:hover {
    background: ${props => (props.disabled ? '#E5E5E5' : '#d94e5b')};
  }

  @media (max-width: 1100px) {
    width: 95%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: -50px;
  }
`

export const TagsGrid = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

export const HashtagTag = styled.a`
  background: #ffffff;
  border: 1px solid #f3c1be;
  border-radius: 87px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  cursor: pointer;
`

export const DesignsContainer = styled(Content)`
  flex-direction: column;
  padding-bottom: 20px;
`

export const DesignsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`

export const DesignsTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;

  @media (max-width: 1000px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 28px;
`

const Time = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  white-space: nowrap;
  display: flex;
  align-items: center;

  align-items: center;

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`

const ImageContainer = styled.div`
  width: 100%;

  background-size: cover;
  border-radius: 16px;
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @media only screen and (min-width: 769px) {
    width: 350px;
  }

  @media only screen and (min-width: 992px) {
    width: 450px;
  }
`

const Clock = styled(ClockIcon)`
  display: block;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  fill: ${props => props.theme.text.primary};
`

const Join = styled.div`
  position: absolute;
  right: 0;
  bottom: 25px;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;

  padding-left: 5px;

  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
`

const User = styled.div`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;
  top: 5px;
`

const UserMore = styled.div`
  background-color: #c4c4c4;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 5px;

  display: flex;
  align-items: center;
  align-self: center;
`

const Badge = styled.div`
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 9px;
  margin-left: 8px;
`

const Coin = styled.div`
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 3px;
`

const JoinAltButton = styled(DMPrimaryAltButton)`
  width: 100%;
  @media (min-width: 769px) {
    max-width: 300px;
  }
`

//-------------------------------------------------------------------------------
//-------------------------------------------------------------------------------

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: ${props => props.theme.text.primary};

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
`

const DesignContainer = styled.div`
  margin: 0 8px;
  @media only screen and (min-width: 769px) {
    margin: 0;
    width: min(50%, 560px);
  }
`

const DescriptionContainer = styled.div`
  background: #f9f9f9;
  border-radius: 16px;
  flex: 1 1 auto;
  padding: 29px 24px;

  @media only screen and (min-width: 769px) {
    margin: 0;
  }
`

const FeedTitleContainer = styled.div`
  display: flex;

  margin: 8px 0 24px;
  color: ${props => props.theme.text.primary} @media only screen and (min-width: 769px) {
    margin: 26px 0;
  }
`

const Title = styled.div`
  flex: 1 1 auto;

  font-size: 24px;
  font-weight: 700;
  line-height: 29px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  overflow: hidden;

  color: ${props => props.theme.text.primary};
`

export const CarouselWrapper = styled(CarouselProvider)`
  position: relative;
`

export const PrevButton = styled(ButtonBack)`
  position: absolute;
  top: calc(48% - 20px);
  left: 12px;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    display: none;
  }

  & img {
    transform: rotate(90deg);
    height: 18px;
  }
`

export const NextButton = styled(PrevButton)`
  left: unset;
  right: 12px;

  & img {
    transform: rotate(-90deg);
    height: 18px;
  }
`

export const JoinedLabel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  height: 36px;
  width: 138px;
  right: 0;
  bottom: 24px;
  border-radius: 18px 0 0 18px;
  z-index: 100;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  & span {
    margin-left: 3px;
  }

  & > div {
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: -6px;
    background: #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  & img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: -4px;
    z-index: 1;
  }
`

export const SlideImage = styled.img`
  width: 560px;
  height: 560px;
  border-radius: 16px;

  @media (max-width: 1100px) {
    width: 100%;
    height: 100%;
  }
`

//-------------------------------------------------------------------------------

const Card = props => {
  return (
    <DesignFeedCard sizeless>
      <JoinedDesignCard {...props}></JoinedDesignCard>
    </DesignFeedCard>
  )
}

//-------------------------------------------------------------------------------

const SectionContainer = styled.div`
  border-bottom: ${props => (props.end ? 'none' : '1px solid #f1f1f1')};
  /*margin-top: 9px;*/
  margin-bottom: 9px;

  @media only screen and (min-width: 769px) {
    margin-bottom: ${props => (props.end === true ? '60px' : '9px')};
  }
`

const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`

const ReqsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

const SectionBody = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 33px;
  display: flex;

  flex-direction: ${props => (props.direction === 'col' ? 'column' : 'row')};
  flex-wrap: wrap;
`

const Section = ({ title, children, direction, end }) => {
  return (
    <SectionContainer end={end === true ? 'true' : 'false'}>
      <SectionTitle>{title}</SectionTitle>
      <SectionBody direction={direction}>{children}</SectionBody>
    </SectionContainer>
  )
}

const RewardPill = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`

export const Reward = ({ type, name, icon }) => {
  if (type === 'badge')
    return (
      <RewardPill>
        <Badge style={{ backgroundImage: `url(${icon})` }} />
        {name}
      </RewardPill>
    )
  if (type === 'dcoin' || type === 'featured')
    return (
      <RewardPill>
        <Coin style={{ backgroundImage: `url(${icon})` }} />
        {name}
      </RewardPill>
    )
  return <RewardPill>{name}</RewardPill>
}

//-------------------------------------------------------------------------------

const TagPill = styled.div`
  border: 1px solid #8b8b8b;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  margin-right: 8px;
  white-space: nowrap;
  margin-bottom: 10px;
`

const Tag = ({ name }) => {
  return <TagPill>#{name}</TagPill>
}

export const NoItemsContainer = styled.div`
  width: 100%;
  background: ${props => (props.scheme === 'light' ? '#F5F5F5' : '#2E2E2E')};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  grid-column: 1 / -1;
  box-sizing: border-box;
`

export const NoItemsImg = styled.img`
  width: 300px;
`

export const NoItemsInfo = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #8b8b8b;
`
//-------------------------------------------------------------------------------

const imgload = async url => {
  const img = new Image()
  img.src = url
  await img.decode()
  return { w: img.width, h: img.height }
}

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min) //The maximum is inclusive and the minimum is inclusive
}

const Challenge = props => {
  const { appearance } = useTheme()

  const { data } = (props.location || {}).state || {}

  const { feed, error, loading } = useSelector(state => state.otherDesigns)

  const history = useHistory()
  const dispatch = useDispatch()
  const id = props.challengeId ? props.challengeId : history.query.id

  const rShareModal = useRef()

  const [challengeData, setChallengeData] = useState(null)

  const [status, setStatus] = useState('')
  const [entries, setEntries] = useState(2)
  const [user1, setUser1] = useState(null)
  const [user2, setUser2] = useState(null)
  const [spacing, setSpacing] = useState('90px')

  const [userStatus, setUserStatus] = useState('new')
  const [submitting, setSubmitting] = useState(false)
  const [selectedTab, setSelectedTab] = useState('newest')

  const [showStaticCoins, setShowStaticCoins] = useState(false)

  const participationStatus = useSelector(state => state?.challenge?.status)
  const profile = useSelector(state => state?.profile?.me)
  const membershipInfo = getMembershipType(profile)

  const [feedId, setFeedId] = useState()

  const tabs = [
    { name: 'Newest', value: 'newest' },
    { name: 'Popular', value: 'popular' }
  ]

  const tick = useCallback(d => {
    const eventTime = moment(d.expiresAt.iso)
    const now = moment()
    const dx = eventTime - now
    const duration = moment.duration(dx, 'milliseconds')

    setStatus(timeLeft(duration))
  }, [])

  useEffect(() => {
    if (!feedId) return
    if (feedId === 'daily') return
    if (feedId === 'newuser') return
    if (feedId === 'requirement') return

    var attr = {
      addition: {
        designContestId: feedId
      }
    }

    if (selectedTab === 'popular') {
      attr = {
        addition: {
          designContestId: feedId,
          mostRecentCreatedAt: new Date(),
          sort: 'numLikes'
        }
      }
    }

    batch(() => {
      dispatch(fetchSaved())
      dispatch(setAttr('otherDesigns')(attr))
      dispatch(fetchFeed('otherDesigns')({ status: FEED_RESET })).then(r => {
        if (r.payload && r.payload.data && r.payload.data.currentUserlikes) dispatch(setSavedLikes(r.payload.data.currentUserlikes))
      })
    })
  }, [dispatch, feedId, selectedTab])

  useEffect(() => {
    if (!challengeData) return

    const interval = setInterval(() => {
      tick(challengeData)
    }, 1000)
    return () => clearInterval(interval)
  }, [challengeData, tick])

  const setup = useCallback(
    (d, s, i) => {
      if (!d) return

      if (d.nuc) {
        delete d.nuc.objectId
        d = {
          ...d,
          ...d.nuc
        }
      }

      setChallengeData(d)
      setFeedId(d.objectId)
      setUserStatus('new')
      setSubmitting(false)
      tick(d)

      const ent = Math.abs(d.numEntries) || 0

      setEntries(ent)
      setUser1(null)
      setUser2(null)

      if (ent === 0) {
        setSpacing('80px')
      }
      if (ent > 0) {
        setSpacing('100px')
        const rand1 = getRandomIntInclusive(1, 50)
        const usr1 = 'https://dm-webapp-prod.s3.amazonaws.com/avatars/avatar' + rand1 + '%403x.png'
        setUser1(usr1)
      }
      if (ent > 1) {
        setSpacing('120px')
        const rand2 = getRandomIntInclusive(1, 50)
        const usr2 = 'https://dm-webapp-prod.s3.amazonaws.com/avatars/avatar' + rand2 + '%403x.png'
        setUser2(usr2)
      }
      if (ent > 2) {
        setSpacing('170px')
      }

      if (d.type === 'daily' || d.type === 'brand' || d.type === 'newUser' || d.type === 'limited') setShowStaticCoins(true)
      else setShowStaticCoins(false)

      if (!s) return
      //check if this challenge have draft or completed

      const cj = s.joined.find(s => s === i) || participationStatus.joined.includes(i)
      if (cj) setUserStatus('joined')
      const cs = s.started.find(s => s === i) || participationStatus.started.includes(i)
      if (cs) setUserStatus('started')
    },
    [participationStatus, tick]
  )

  //Init - check if data was passed or need to be loaded
  useEffect(() => {
    const loadData = async () => {
      const r = await getChallengeById(id)
      const s = await getMyChallengeStatus()

      if (r) setup(r, s, r.objectId)
    }

    const loadStatusOnly = async d => {
      const s = await getMyChallengeStatus()
      setup(d, s, id)
    }

    const loadRecent = async type => {
      const r = await getChallengeByType(type)
      const s = await getMyChallengeStatus()

      if (r.length === 0) {
        history.push('/challenges')
        return
      }

      const requiredObject = r.find(r => r.type === type && r.status !== 'closed')

      if (requiredObject) {
        setup(requiredObject, s, requiredObject?.objectId)
      } else {
        alert("We don't have any active challenge of this type. Check back later!")

        history.push('/challenges')

        return
      }
    }

    if (id === 'daily') {
      loadRecent('daily')
      return
    } else if (id === 'newuser') {
      loadRecent('newUser')
      return
    } else if (id === 'requirement') {
      loadRecent('brand')
      return
    } else if (id === 'limited') {
      loadRecent('limited')
      return
    } else if (id === 'pro') {
      loadRecent('pro')
      return
    } else if (!data && id) {
      loadData(id)
    } else {
      loadStatusOnly(data)
    }
  }, [id, data, setup, history])

  const handleFetch = e => {
    dispatch(fetchFeed('otherDesigns')({ status: null })).then(r => {
      if (r.payload && r.payload.data && r.payload.data.currentUserlikes) dispatch(setSavedLikes(r.payload.data.currentUserlikes))
    })
  }

  const checkAlreadyDone = async e => {
    const s = await getMyChallengeStatus()
    if (s) {
      const cj = s.joined.find(s => s === id)
      if (cj) {
        setUserStatus('joined')
        return true
      }
    }
    return false
  }

  const handleNew = async e => {
    e.stopPropagation()

    if ((profile.userLevel || 0) < challengeData.levelRequired && !membershipInfo.isMember) {
      dispatch(setShowMembershipModal(true))
      return
    }

    if (!challengeData) return

    const isDone = await checkAlreadyDone()
    if (isDone) {
      setSubmitting(false)
      return
    }

    history.push(`/create/challenge/${challengeData.objectId}`)
  }

  const handleContinue = async e => {
    e.stopPropagation()
    if (!challengeData) return
    setSubmitting(true)

    const isDone = await checkAlreadyDone()
    if (isDone) {
      setSubmitting(false)
      return
    }

    history.push(`/create/challenge/${challengeData.objectId}`)
  }

  const handleShare = (lnk, ttl, img, pos) => {
    //console.log(lnk, ttl, img, pos)
    rShareModal.current.show(lnk, ttl, img, pos)
  }

  useEffect(() => {
    if (!history.query.designs || !challengeData) return
    document.getElementById('design-section')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    })
  }, [history.query.designs, challengeData])

  if (!challengeData) return <Detail title="" backToRoot />

  return (
    <>
      <DMShareModal ref={rShareModal} />
      <EventDetailWrapper>
        {!props.hideBackButton && (
          <BackButtonWrapper>
            <BackButton autoPosition={false} />
          </BackButtonWrapper>
        )}
        <Content>
          <LeftPart>
            <TitleContainer>
              <Title>{challengeData.title}</Title>
              <Time>
                <Clock />
                {status}
              </Time>
            </TitleContainer>
            <CarouselWrapper naturalSlideWidth={494} naturalSlideHeight={494} totalSlides={2}>
              <PrevButton>
                <img src={ArrowIcon} alt="" />
              </PrevButton>
              <NextButton as={ButtonNext}>
                <img src={ArrowIcon} alt="" />
              </NextButton>
              <Slider>
                <Slide index={0}>
                  <SlideImage src={challengeData?.thumbImgUrl} alt="" />
                </Slide>
                <Slide index={1}>
                  <SlideImage src={challengeData?.backdropImgUrl} alt="" />
                </Slide>
              </Slider>
              <JoinedLabel>
                {(feed || []).slice(0, 3).map(data => (
                  <img src={data?.user?.funAvatarImageUrl || data?.user?.thumbProfileImageFile?.url || 'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'} />
                ))}
                {feed.length > 3 && <div>...</div>}
                <span>{entries || 0} joined</span>
              </JoinedLabel>
            </CarouselWrapper>
          </LeftPart>
          <RightPart>
            {challengeData.rewardInfo && (
              <Section title="Reward" direction="col">
                {showStaticCoins && <Reward type="dcoin" name={`Earn ${challengeData.type === 'newUser' ? 150 : 300} Dcoins`} icon={ASSET_URL + 'designer/coin_success.png'} />}

                {challengeData.rewardInfo.map((d, i) => {
                  if (showStaticCoins && d.type === 'dcoin') return <></>
                  return <Reward key={d.type + i} type={d.type} name={d.description} icon={d.type === 'badge' ? d.imageFiles[d.imageFiles.length - 1].url : ASSET_URL + 'designer/coin_success.png'} />
                })}
              </Section>
            )}
            {challengeData.specialRequirements && (
              <Section title="Requirements">
                <ReqsContainer>
                  {challengeData.specialRequirements.tasks.map((d, i) => (
                    <RequirementPill key={i} data={d} colorize style={{ marginBottom: '8px' }} />
                  ))}
                </ReqsContainer>
              </Section>
            )}

            {challengeData.requirement && <Section title="Story">{challengeData.requirement}</Section>}

            {challengeData.hashtags && challengeData.hashtags.length > 0 && (
              <Section title="Tags" end>
                <TagsGrid>
                  {challengeData.hashtags &&
                    challengeData.hashtags.map((tag, i) => (
                      <Link href={`/hashtag/${tag}`} key={tag}>
                        <HashtagTag>#{tag}</HashtagTag>
                      </Link>
                    ))}
                </TagsGrid>
              </Section>
            )}
          </RightPart>
        </Content>
        {status === 'closed' ? (
          <BigActionButton disabled={true}>Closed</BigActionButton>
        ) : (
          <>
            {userStatus === 'new' && (
              <BigActionButton onClick={handleNew} disabled={submitting}>
                {(profile.userLevel || 0) < challengeData.levelRequired && !membershipInfo.isMember ? `Members/Level ${challengeData.levelRequired}+` : 'Join Now'}
              </BigActionButton>
            )}
            {userStatus === 'started' && (
              <BigActionButton onClick={handleContinue} disabled={submitting}>
                Continue
              </BigActionButton>
            )}
            {userStatus === 'joined' && <BigActionButton disabled={true}>Joined</BigActionButton>}
          </>
        )}
        <DesignsContainer>
          <DesignsHeader>
            <DesignsTitle id="design-section">Joined Designs ({entries})</DesignsTitle>
            <DMTabs tabs={tabs} value={selectedTab} onChange={e => setSelectedTab(e)} />
          </DesignsHeader>

          <DesignsGrid designs>
            {feed && feed.length === 0 && (
              <NoItemsContainer scheme={appearance}>
                <NoItemsImg src={NoItems} alt={'no-items'} />
                <NoItemsInfo>No Design Yet</NoItemsInfo>
              </NoItemsContainer>
            )}
            {feed && feed.length > 0 && <NestedFeed data={feed} error={error} loading={loading} card={Card} onFetch={handleFetch} onShare={handleShare} />}
          </DesignsGrid>
        </DesignsContainer>
      </EventDetailWrapper>
    </>
  )
}

export default Challenge
