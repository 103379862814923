import React from 'react'
import styled from 'styled-components'
import { ReactComponent as FavoriteIcon } from '../../assets/favorite.svg'
import { ReactComponent as ShareIcon } from '../../assets/share.svg'
import { ReactComponent as SaveIcon } from '../../assets/bookmark.svg'

const CardButton = styled.button`
  display: flex;
  align-items: center;

  color: ${props => (props.active ? '#FF5E6D' : '#8B8B8B')};
  border-radius: 5px;
  padding: 8px;
  margin: 0;
  background: rgba(255, 255, 255, 0.9);

  border: none;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: ${({ active }) => (active ? '#FF5E6D' : '#333')};
  }
`

const Favorite = styled(FavoriteIcon)`
  width: 15px;
  height: 15px;
  margin-right: 8px;
  fill: ${props => (props.active ? '#FF5E6D' : '#8B8B8B')};
`

const FavButton = styled(CardButton)`
  &:hover ${Favorite} {
    fill: ${({ active }) => (active ? '#FF5E6D' : '#333')};
  }
`

const Share = styled(ShareIcon)`
  width: 15px;
  height: 15px;
  margin-right: 8px;
  fill: ${props => (props.active ? '#FF5E6D' : '#8B8B8B')};
`

const ShareButton = styled(CardButton)`
  &:hover ${Share} {
    fill: ${({ active }) => (active ? '#FF5E6D' : '#333')};
  }
`

const Save = styled(SaveIcon)`
  width: 15px;
  height: 15px;
  margin-right: 8px;
  fill: ${props => (props.active ? '#FF5E6D' : '#8B8B8B')};
`

const SaveButton = styled(CardButton)`
  &:hover ${Save} {
    fill: ${({ active }) => (active ? '#FF5E6D' : '#333')};
  }
`

export const DMCardButton = ({ children }) => {
  return <CardButton>{children}</CardButton>
}

export const DMLikeCardButton = ({ active, number, ...props }) => {
  return (
    <FavButton active={active} {...props}>
      <Favorite active={!!active} />
      {number || 'Like'}
    </FavButton>
  )
}

export const DMShareCardButton = ({ active, ...props }) => {
  return (
    <ShareButton active={active} {...props}>
      <Share active={!!active} />
      Share
    </ShareButton>
  )
}

export const DMSaveCardButton = ({ active, ...props }) => {
  return (
    <SaveButton active={active} {...props}>
      <Save active={!!active} />
      Save{active && 'd'}
    </SaveButton>
  )
}
