import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BackIcon } from '../../assets/backcircle.svg'
import { useIsMobile } from '../../hooks/useIsMobile'
import useMediaQuery from '../../hooks/useMediaQuery'

const Container = styled.div`
  position: relative;
  padding-bottom: 56px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    margin-top: 40px;
    flex-direction: row;
    justify-content: center;
  }
`

const Center = styled.div`
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex: 0 0 768px;
    width: 768px;
  }

  @media only screen and (min-width: 992px) {
    flex: 0 0 992px;
    width: 992px;
  }
  @media only screen and (min-width: 1400px) {
    flex: 0 0 1370px;
    width: 1370px;
  }
`

const Title = styled.div`
  user-select: none;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.text.primary};
  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
`

const DecoTitle = styled.div`
  user-select: none;
  white-space: nowrap;
  color: ${props => props.theme.text.primary};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-top: 15px;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 4px;
  }
`

const Subtitle = styled.div`
  user-select: none;
  white-space: nowrap;
  color: ${props => props.theme.text.primary};
  font-size: 12px;
  font-weight: 500;

  overflow: hidden;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }
`

const Back = styled(BackIcon)`
  cursor: pointer;
`

const BackContainer = styled.div`
  position: absolute;
  top: -17px;

  @media only screen and (min-width: 768px) {
    left: 16px;
    top: -10px;
  }
`

const Header = styled.div`
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 40px;
  }
`

const Detail = ({
  children,
  title,
  subtitle,
  decoration,
  backToRoot,
  headerActions = null
}) => {
  const history = useRouter()
  const isMobile = useIsMobile()
  const isTablet = useMediaQuery('(max-width: 1024px)')

  const handleBack = () => {
    if (backToRoot) {
      location.pathname = '/'
    } else history.back()
  }

  return (
    <Container>
      {!isMobile && !isTablet && (
        <BackContainer>
          <Back onClick={handleBack} />
        </BackContainer>
      )}
      <Center>
        <HeaderWrapper>
          <div>
            <Header>
              {title && <Title>{title}</Title>}
              {decoration && <DecoTitle>{decoration}</DecoTitle>}
            </Header>
            {subtitle && (
              <Header>
                <Subtitle>{subtitle}</Subtitle>
              </Header>
            )}
          </div>
          {headerActions}
        </HeaderWrapper>
        {children}
      </Center>
    </Container>
  )
}

export default Detail
