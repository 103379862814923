import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div`
  box-sizing: border-box;

  ${props =>
    !props.sizeless &&
    `
    width: 45vmin;
  height: 45vmin;

  @media (min-width: 768px) {
    width: 244px;
    height: 244px;
  }

  @media only screen and (min-width: 992px) {
    width: 314px;
    height: 314px;
  }
  `}
`
const DesignFeedCard = ({ sizeless, children }) => {
  return <CardContainer sizeless={sizeless}>{children}</CardContainer>
}

export default DesignFeedCard
