import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import {
  DMLikeCardButton,
  DMShareCardButton,
  DMSaveCardButton
} from '../../DesignerTheme/DMCardButton'
import {
  setFavorite,
  setUnfavorite,
  quickSetFavorite,
  quickSetUnfavorite,
  setLike,
  setUnlike,
  quickSetLike,
  quickSetUnlike
} from '../../../redux/designerReducers/userSavedReducer'
import { getDomain } from '../../../utils/fetchUtils'

const Card = styled.div`
  background-color: ${props => props.theme.designcard.background};

  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  width: 100%;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(0, 0, 0, 0.5) 0.01%,
    rgba(121, 121, 121, 0) 47.4%,
    rgba(0, 0, 0, 0.38) 100%
  );

  transition: visibility 0.2s, opacity 0.2s linear;
`

const TopRight = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

const BottomRight = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

const ProfileContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: white;
`

const ProfilePic = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 8px;
`

const JoinedDesignCard = ({
  refFn,
  data,
  children,
  menuItems,
  onClick,
  onShare,
  ...props
}) => {
  const dispatch = useDispatch()
  const { savedDesigns, savedLikes } = useSelector(state => state.userSaved)
  const [showOverlay, setShowOverlay] = useState('hidden')
  const [image, setImage] = useState()
  const [username, setUsername] = useState(null)
  const [pic, setPic] = useState(null)
  const [saved, setSaved] = useState(false)
  const [liked, setLiked] = useState(false)
  const [likeDiff, setLikeDiff] = useState(0)

  useEffect(() => {
    const defaultpic =
      'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'

    if (data.idea && data.idea.thumbImageFile)
      setImage(data.idea.thumbImageFile.url)
    if (data.idea && data.idea.cfThumbImageUrl)
      setImage(data.idea.cfThumbImageUrl)
    if (data.user) {
      if (data.user.uniqueDisplayName) setUsername(data.user.uniqueDisplayName)
      if (data.user.thumbProfileImageFile)
        setPic(data.user.thumbProfileImageFile.url)
      else if (data.user.cfTbImageUrl) setPic(data.user.cfTbImageUrl)
      else setPic(defaultpic)
    }
  }, [data])

  useEffect(() => {
    if (data.objectId) {
      if (savedLikes[data.objectId]) setLiked(true)
      else setLiked(false)

      if (savedDesigns[data.objectId]) setSaved(true)
      else setSaved(false)
    }
  }, [data, savedDesigns, savedLikes])

  const handleMouseOver = e => {
    e.preventDefault()
    setShowOverlay('visible')
  }

  const handleMouseOut = e => {
    e.preventDefault()
    setShowOverlay('hidden')
  }

  const handleLike = e => {
    e.stopPropagation()
    if (!data.objectId) return
    if (savedLikes[data.objectId]) {
      setLikeDiff(likeDiff - 1)
      dispatch(quickSetUnlike(data.objectId))
      dispatch(setUnlike(data.objectId))
    } else {
      setLikeDiff(likeDiff + 1)
      dispatch(quickSetLike(data.objectId))
      dispatch(setLike(data.objectId))
    }
  }

  const handleSave = e => {
    e.stopPropagation()
    if (!data.objectId) return
    if (savedDesigns[data.objectId]) {
      dispatch(quickSetUnfavorite(data.objectId))
      dispatch(setUnfavorite(data.objectId))
    } else {
      dispatch(quickSetFavorite(data.objectId))
      dispatch(setFavorite(data.objectId))
    }
  }

  const handleShare = e => {
    e.stopPropagation()
    const ttl = data.title ? data.title : 'DecorMatters'

    const lnk = 'https://www.decormatters.com/design/' + data.objectId
    if (onShare) onShare(lnk, ttl, image, { x: e.pageX, y: e.pageY })
  }

  const handleToDesign = e => {
    const url = getDomain(window.location.href) + '/design/' + data.objectId
    window.location.href = url
  }
  return (
    <Card
      ref={refFn}
      id="overlay"
      style={{ backgroundImage: `url(${image})`, ...props.style }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleToDesign}
    >
      <Overlay
        style={{
          visibility: showOverlay,
          opacity: showOverlay === 'visible' ? 1 : 0
        }}
      >
        <ProfileContainer>
          {pic && <ProfilePic style={{ backgroundImage: `url(${pic})` }} />}
          {username}
        </ProfileContainer>
        <TopRight>
          <DMShareCardButton onClick={handleShare} />
        </TopRight>
        <BottomRight>
          <DMLikeCardButton
            active={liked}
            number={(data?.numLikes || 0) + likeDiff}
            onClick={handleLike}
          />
          <DMSaveCardButton active={saved} onClick={handleSave} />
        </BottomRight>
      </Overlay>
    </Card>
  )
}

export default JoinedDesignCard
