import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const TabsContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.drawer.titleBackground};
  box-sizing: border-box;
  border-radius: 5px;
`

const TabContainer = styled.button`
  margin: 0;
  padding: 5px 12px;
  background-color: ${props =>
    props.active ? props.theme.drawer.titleBackground : 'transparent'};
  white-space: nowrap;
  border: none;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  color: ${props => props.theme.text.primary};
  @media only screen and (min-width: 768px) {
    padding: 15px 23px;
  }
`

export const DMTab = ({ children, selected, value, onClick, ...props }) => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (value === selected) setActive(true)
    else setActive(false)
  }, [value, selected])
  return (
    <TabContainer active={active} onClick={e => onClick(value)} {...props}>
      {children}
    </TabContainer>
  )
}

export const DMTabs = ({ tabs, value, onChange }) => {
  const handleClick = e => {
    if (onChange) onChange(e)
  }

  return (
    <TabsContainer>
      {tabs &&
        tabs.map((d, i) => (
          <DMTab key={i} selected={value} value={d.value} onClick={handleClick}>
            {d.name}
          </DMTab>
        ))}
    </TabsContainer>
  )
}
