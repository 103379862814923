import React, { useCallback, useRef } from 'react'

const NestedFeed = ({ data, onFetch, card, loading, error, ...props }) => {
  const ob = useRef()
  const lastFeedItem = useCallback(
    node => {
      if (ob.current) ob.current.disconnect()
      ob.current = new IntersectionObserver(
        e => {
          if (e[0].isIntersecting) {
            if (onFetch) onFetch()
          }
        },
        {
          threshold: 1
        }
      )
      if (node) ob.current.observe(node)
    },
    [onFetch]
  )

  if (!card) return <></>
  if (loading === 'pending') return <></>
  if (!data) return <></>
  if (data && data.length === 0 && error === 'empty') return <></>

  return (
    <>
      {data.map((d, i) => {
        const Card = card
        return <Card key={i} data={d} refFn={lastFeedItem} {...props} />
      })}
    </>
  )
}

export default NestedFeed
